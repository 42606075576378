<template>
    <modal class="posPrintMonitorModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">打印监控服务 </div>
        <div class="modal-body">
            <div class="li-box">
                <div class="name">1:打印服务状态</div>
                <div class="content">
                    <div class="li-flex print" v-if="printInfo" :class="{stop:!printInfo?.Is_PrintOnline}">
                        <div class="lable-text"><i class="iconfont icon-a-dayinmoban1"></i></div>
                        <div class="from-input" v-if="printInfo?.Is_PrintOnline">正常运行中...</div>
                        <div class="from-input" v-else>离线中...</div>
                    </div>
                    <div class="li-flex print error" v-else>
                        <div class="lable-text"><i class="el-icon-error"></i></div>
                        <div class="from-input">打印服务未开启</div>
                    </div>
                </div>
            </div>
            <div class="li-box" v-if="printInfo">
                <div class="name">2:运行服务信息</div>
                <div class="content">
                    <div class="li-flex">
                        <span class="lable-text">名称：</span>
                        <div class="from-input">{{printInfo?.Site_Name}}</div>
                    </div>
                    <div class="li-flex">
                        <span class="lable-text">序列号：</span>
                        <div class="from-input">{{printInfo?.Site_Serial}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">返回</button>
            <button class="btn selected" v-show="isWindow && printInfo && userInfo && printInfo?.Site_Serial==userInfo?.Site_Serial" @click="isShowOldToNew=true">调整打印机内容</button>
            <button class="btn selected" @click="isCreanPrintShow=true">清除打印数据</button>
            <button class="btn selected" @click="prompt(1)" v-if="printInfo">停用打印服务</button>
            <button class="btn selected" @click="prompt()" v-else>启用打印服务</button>
        </div>
        <teleport to="body">
            <modal class="creanPrintModel" :isShow="isCreanPrintShow" >
                <div class="header">
                    提示
                </div>
                <div class="modal-body">
                    <div class="box__message">清除打印数据密码验证</div>
                    <div class="el-message-box__input">
                        <el-input ref="pw" :class="{invalid:isError}" v-model="password" type="password" @keydown.enter="creanPrintData()"></el-input>
                        <div class="el-message-box__errormsg" :style="{visibility:isError?'':'hidden'}">密码错误</div>
                    </div>
                    <div><el-checkbox v-model="isAll" label="云端打印任务也同时清除(不勾选仅清除本地任务)" ></el-checkbox></div>
                </div>
                <div class="footer-box">
                    <button class="btn" @click="isCreanPrintShow=false">取消</button>
                    <button class="btn selected" @click="creanPrintData()">确认</button>
                </div>
            </modal>
        </teleport>
        <modal-load :isShow="isShowOldToNew">
            <print-old-to-new-model :isShow="isShowOldToNew" @close="isShowOldToNew=false"></print-old-to-new-model>
        </modal-load>
    </modal>
</template>

<script>
import {config} from '../../../../common'
import printOldToNewModel from './printOldToNewModel/printOldToNewModel.vue';
// 打印监控服务
export default {
  components: { printOldToNewModel },
    name:'posPrintMonitorModel',
    props:{
        isShow:Boolean,
    },
    data(){
        return{
            /**是否是pc 设备 */
			isWindow:this.$webBrowser.isMobile.Windows,
            userInfo:{},
            printInfo:null,
            isCreanPrintShow:false,
            password:'',
            isError:false,
            isAll:false,
            /**调整打印机内容 弹层 */
            isShowOldToNew:false,
            
        }
    },
    mounted(){
        this.userInfo=this.$auth.getUserInfo(); 
        this.$nextTick(()=>{
            this.loadData();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.isCreanPrintShow=false;
                this.$nextTick(()=>{
                    this.loadData();
                });
            }
        },
        isCreanPrintShow(newValue){
            if(newValue){
                this.password='';
                this.isError=false;
                this.isAll=false;
                this.$nextTick(()=>{
                    this.$refs.pw.focus();
                })
            }
        },
        password(){
            this.isError=false;
        }
    },
    methods:{
        //加载 站点状态
        loadData(){
            const loading = this.$loading({
                text: "加载打印服务站点数据...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetPrintServer",{}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody){
                        this.printInfo=d.ResponseBody;
                    }else{
                        this.printInfo=null;
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('加载打印服务站点数据失败:'+e.message);
                console.error('载打印服务站点数据失败:',e);
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        /**开启打印服务 */
        open(){
            const loading = this.$loading({
                text: "开启打印服务中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.OpenPrintService",{}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    let userInfo=this.$auth.getUserInfo(); 
                    this.printInfo={
                        Is_PrintOnline:false,
                        Site_ID:config?.UUID,
                        Device_ID:this.global?.devices?.uuid,
                        Site_Name:userInfo?.Site_Name,
                        Site_Serial:userInfo?.Site_Serial
                    }
                    this.$webBrowser.openPrintService().then(()=>{
                        this.printInfo.Is_PrintOnline=true;
                        this.$message.success("启用成功");
                    }).catch((e)=>{
                        this.$message.error('开启本地打印服务失败',e);
                    })
                    
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('开启打印服务失败：'+e.message);
                console.error('开启打印服务失败：',e);
            })
        },
        /**停用打印服务 */
        stop(){
            const loading = this.$loading({
                text: "停用打印服务中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.StopPrintService",{ 
                //设备id
                Site_ID:this.printInfo.Site_ID,
                /**设备硬件ID */
                Device_ID:this.printInfo.Device_ID,
                Site_Name: this.printInfo.Site_Name,//运行打印服务的设备名称
                Site_Serial: this.printInfo.Site_Serial,//运行打印服务的设备序列号
                
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(this.printInfo.Device_ID==this.$global.devices?.uuid){
                        this.$webBrowser.openPrintService();
                    }
                    
                    this.$message.success("停用成功");
                    this.printInfo=null;
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('停用打印服务失败：'+e.message);
                console.error('停用打印服务失败:',e);
            })
        },
        prompt(type){
            let inputValue='';
            this.$prompt((type==1?"停用":"启用")+"服务密码验证", "提示", {
                confirmButtonText: "确定",
                cancelButtonText:"取消",
                inputType:'password',
                closeOnHashChange:false,
                inputValidator:(value)=>{
                    let isbo= value=='' || value!=inputValue;
                    if(isbo){
                        inputValue='';
                    }
                    return isbo;
                },
                inputErrorMessage: '密码错误',
                beforeClose:(action,instance,done)=>{
                    if(action=='confirm'){
                        if(instance.inputValue!="admin"+(new Date).Format('MMdd')){
                            inputValue=instance.inputValue;
                            instance.inputValue='';
                            this.$nextTick(()=>{
                                instance.inputValue=inputValue;
                            })
                            return;
                        }
                    }
                    done();
                }
            }).then(()=>{
                if(type==1){
                    this.stop();
                }else{
                    this.open();
                }
            })
        },
        /**清除打印数据 */
        creanPrintData(){
            if(this.password!="admin"+(new Date).Format('MMdd')){
                this.isError=true;
            }else{//
                const loading = this.$loading({
                    text: "清除打印数据中...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                let userInfo=this.$auth.getUserInfo();
                let param={
                    User_ID:userInfo.User_ID, //登陆用户ID
                    ClearCloudData:this.isAll
                }
                this.$httpAES.post("Bestech.CloudPos.ClearPrintData",param).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode==0){
                        this.$message.success("清除打印数据成功"); 
                        this.isCreanPrintShow=false;
                    }else{
                        this.$message.error("清除打印数据失败："+d.ResponseHeader.ResultDesc);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$message.error('清除打印数据失败：'+e.message);
                    console.log('清除打印数据失败：',e);
                }) 
            }
        }
    }
}
</script>

<style lang="scss">
@import './posPrintMonitorModel.scss'
</style>